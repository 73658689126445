import React, { Component } from 'react';
import './styles.scss';

class ContantUs extends Component {

    componentDidMount()
    {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

	render() {
		return (
			<div className="contact-us">
                <p className="contact-us-title">צור קשר</p>
                <div className="contact-flex">
                    <div className="contact-box">
                        <i className="fa-solid fa-map-location-dot"></i>
                        <p className="box-title">כתובת</p>
                        <p className="box-value">עפולה - יצחק נבון 3</p>
                    </div>
                    <div className="contact-box">
                        <i className="fa-solid fa-mobile-screen-button"></i>
                        <p className="box-title">מס' טלפון</p>
                        <p className="box-value">052-6772038</p>
                        <p className="box-value">04-8600630</p>
                        <p className="box-value">04-8600636</p>
                    </div>
                    <div className="contact-box">
                        <i className="fa-solid fa-at"></i>
                        <p className="box-title">מייל</p>
                        <p className="box-value">at.hagalel@gmail.com</p>
                    </div>
                </div>
			</div>
		);
	}
}

export default ContantUs;
