import React, { Component } from 'react';
import axios from 'axios';
import Loading from '../../../container/Loading';
import configData from '../../../includes/config.json';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import './styles.scss';

class AdminHome extends Component {
	constructor() {
		super();
		this.state = {
			data_columns: [],
			data_rows: [],
			loading: false,
			pageLimit: 50
		};
		this.setPageLimit = this.setPageLimit.bind(this);
	}

	async componentDidMount() {
		const accountID = localStorage.getItem('loggedId');
		if (!accountID) {
			window.location = '/admin';
			return;
		}
		const data_columns = [
			{ name: 'full_name', header: 'שם מלא', defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
			{ name: 'email', header: 'מייל', defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
			{ name: 'phone_number', header: "מס' טלפון", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
			{ name: 'job', header: 'תחום מבוקש', defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
			{ name: 'location', header: 'מיקום', defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
			{ name: 'client_ip', header: 'IP', defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
		]
		this.setState({
			loading: true
		});
		var Buffer = require('buffer/').Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
		const response = await axios({
			method: 'POST',
			url: configData.server_URI + '/koah-adam/admin/get-clients',
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		this.setState({
			data_columns: data_columns,
			data_rows: response.data,
			loading: false
		});
	}

	setPageLimit(e) {
		this.setState({
			pageLimit: e,
		});
	}

	render() {
		const filterValue = [
			{ name: 'full_name', operator: 'contains', type: 'string', value: '' },
			{ name: 'email', operator: 'contains', type: 'string', value: '' },
			{ name: 'phone_number', operator: 'contains', type: 'string', value: '' },
			{ name: 'job', operator: 'contains', type: 'string', value: '' },
			{ name: 'location', operator: 'contains', type: 'string', value: '' },
			{ name: 'ip', operator: 'contains', type: 'string', value: '' },
		];
		let rows_in_page = 7;
		if (this.state.data_rows?.length >= this.state.pageLimit) {
			rows_in_page = this.state.pageLimit;
		} else {
			if (this.state.data_rows.length > 7) {
				rows_in_page = this.state.data_rows?.length;
			}
		}
		const gridStyle = { minHeight: 125 + 2 + rows_in_page * 40 };
		return (
			<div className="Admin">
				{this.state.loading && <Loading/> }
				<ReactDataGrid
					idProperty="id"
					style={gridStyle}
					columns={this.state.data_columns}
					dataSource={this.state.data_rows}
					pagination={true}
					enableSelection={false}
					multiSelect={false}
					enableFiltering={true}
					defaultFilterValue={filterValue}
					checkboxColumn={false}
					editable={false}
					columnUserSelect={true}
					onLimitChange={this.setPageLimit}
				/>
			</div>
		);
	}
}

export default AdminHome;
