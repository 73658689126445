import React, { Component } from "react";
import { Switch, Route, Redirect} from "react-router-dom";
import "@fontsource/cairo";
import "./css/common.scss";
import ConfigData from "./includes/config.json";
import Home from "./pages/Home";
import ThankyouPage from "./pages/ThankyouPage";
import ContantUs from "./pages/ContantUs";
import Header from "./container/Header";
import Footer from "./container/Footer";

//admin
import AdminLogin from "./admin/pages/AdminLogin";
import AdminHeader from "./admin/components/AdminHeader";
import AdminHome from "./admin/pages/AdminHome";

class App extends Component
{

  updateEnvironmentVariables()
  {
    ConfigData.owner_id = process.env.REACT_APP_OWNER_ID;
  }

  render() {
    this.updateEnvironmentVariables();
    var header_to_use = <Header />;
    if(window.location.pathname.includes('admin')){
      header_to_use = <AdminHeader />;
    }
    return (
      <div className="App">
        {header_to_use}
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/contact-us" component={ContantUs} />
          <Route exact path="/thankyou" component={ThankyouPage}/>
          <Route exact path="/admin" component={AdminLogin}/>
          <Route exact path="/admin/home" component={AdminHome}/>
          <Redirect to="/" />
        </Switch>
        {!window.location.pathname.includes('admin') && <Footer/>}
      </div>
    );
  }
}

export default App;