import React, { Component } from "react";
import './styles.scss';

class AdminHeader extends Component
{

  constructor()
  {
    super();
    this.LoggoutBtn_handler = this.LoggoutBtn_handler.bind(this);
  }

  LoggoutBtn_handler(e)
  {
    localStorage.removeItem("loggedId");
    window.location = "/admin";
  }

  render() {
    var loggoutBtn = '';
    const accountID = localStorage.getItem("loggedId");
    if(accountID)
    {
        loggoutBtn = <button className="AdminHeaderLoggoutBtn" onClick={this.LoggoutBtn_handler}>יציאה</button>;
    }
    return (
      <div className="AdminHeader">
        <span className="AdminHeaderTitle">לוח ניהול</span>
        {loggoutBtn}
      </div>
    );
  }

}

export default AdminHeader;