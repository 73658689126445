import React, { Component } from 'react';
import './styles.scss';

class Footer extends Component {
	render() {
		return (
			<div className="footer">
				<div className="footer-content">
					<p className="footer-text">ע.ט הגליל - כוח אדם ואחזקה 2017 בע"מ</p>
				</div>
			</div>
		);
	}
}

export default Footer;
