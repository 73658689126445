import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './styles.scss';

class Header extends Component {
	toggleMobileMenu(e)
	{
		let menu = e.target.closest('.routes').querySelector('.routes-menu')
		if (menu.classList.contains('show')) {
			menu.classList.remove('show');
		} else {
			menu.classList.add('show');
		}
	}	

	render() {
		return (
			<div className="header">
				<NavLink to="/" className="header-logo">
					<span className="logo-first-line">ע.ט הגליל</span>
					<div className="logo-hr"></div>
					<span className="logo-second-line">כוח אדם ואחזקה</span>
				</NavLink>
				<div className="routes desktop">
					<NavLink className="nav-route" to="/">דף הבית</NavLink>
					<NavLink className="nav-route" to="/contact-us">צור קשר</NavLink>
				</div>
				<div className="routes mobile">
					<button className="menu-button" onClick={this.toggleMobileMenu}><i className="fa-solid fa-bars"></i></button>
					<div className="routes-menu">
						<button className="close-button" onClick={this.toggleMobileMenu}><i className="fa-solid fa-xmark"></i></button>
						<p className="menu-title">ברוכים הבאים</p>
						<NavLink className="nav-route" to="/" onClick={this.toggleMobileMenu}>דף הבית</NavLink>
						<NavLink className="nav-route" to="/contact-us" onClick={this.toggleMobileMenu}>צור קשר</NavLink>
					</div>
				</div>
			</div>
		);
	}
}

export default Header;
