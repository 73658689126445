import React, { Component } from 'react';
import configData from '../../includes/config.json';
import axios from 'axios';
import './styles.scss';

class Home extends Component {

	constructor(props)
	{
		super(props);
		this.state = {
			button_value: 'שלח',
			error_message: ''
		}
		this.scrollToForm = this.scrollToForm.bind(this);
		this.handleSubmitBtn = this.handleSubmitBtn.bind(this);
		this.onValueChange = this.onValueChange.bind(this);
	}

	scrollToForm(e) {
		e.preventDefault();
		this.formRef.scrollIntoView();
	}

	onValueChange()
	{
		const elements = document.getElementsByClassName("input-style");
		for(let i=0; i<elements.length; i++)
		{
			const element = elements[i];
			element.classList.remove("error");
		}
		this.setState({
			error_message: ''
		});
	}

	makeBorderRed(el)
	{
		const elements = document.getElementsByClassName("input-style");
		for(let i=0; i<elements.length; i++)
		{
			const element = elements[i];
			if(element.name === el) {
				element.classList.add("error");
			}
		}
	}

	async handleSubmitBtn(e) {
		e.preventDefault();
		const regex = new RegExp(/^(?:\+?0*?972)?0?5[0-9]{8}$/);
		if (!regex.test(e.target.phone_number.value)) {
			this.makeBorderRed("phone_number");
			this.setState({
				error_message: 'פרטים שגויים',
			});
			return;
		}
		if (e.target.job.value === 'בחר תחום עבודה') {
			this.makeBorderRed("job");
			this.setState({
				error_message: 'פרטים שגויים',
			});
			return;
		}
		e.target.submitBtn.disabled = true;
		this.setState({
			button_value: 'רק רגע',
			error_message: ''
		});
		const request = {
			full_name: e.target.full_name.value.substring(0, 55),
			email: e.target.email.value.substring(0, 55),
			phone_number: e.target.phone_number.value,
			job: e.target.job.value
		};
		var Buffer = require('buffer/').Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
		await axios({
			method: 'POST',
			url: configData.server_URI + '/koah-adam/add-new-client',
			data: request,
			headers: {
                'Authorization': `Basic ${token}`
            }
		});
		e.target.submitBtn.disabled = false;
		this.setState({
			button_value: 'שלח',
			error_message: ''
		});
		e.target.reset();
		this.props.history.push('/thankyou');
	}

	render() {
		return (
			<div className="page-content">
				<div className="home-header">
					<div className="left"></div>
					<div className="right">
						<div className="company-name">
							<p className="logo-title">ע.ט הגליל</p>
							<p className="logo-title2">כוח אדם ואחזקה</p>
						</div>
						<button className="register-btn" onClick={this.scrollToForm}>הירשם עכשיו</button>
					</div>
				</div>
				<div className="our-work">
					<p className="our-work-title">בתחומים האלה אנחנו חזקים</p>
					<div className="our-work-flex">
						<div className="our-work-box se3od">
							<div className="icon">
								<i className="fa-solid fa-stethoscope"></i>
							</div>
							<span className="box-label">סיעוד</span>
							<span className="box-description">העוסקים בסיעוד נקראים אחים או אחיות והם מובדלים מספקי שירותי בריאות אחרים על ידי גישתם לטיפול בחולה, הכשרתם והיקף עבודתם המעשית</span>
						</div>
						<div className="our-work-box mef3al">
							<div className="icon">
								<i className="fa-solid fa-industry"></i>
							</div>
							<span className="box-label">מפעלים</span>
							<span className="box-description">מפעל הוא סדנת ייצור גדולה, לרוב ממוכנת, שבה מייצרים דברים</span>
						</div>
						<div className="our-work-box yetsor">
							<div className="icon">
								<i className="fa-solid fa-circle-plus"></i>
							</div>
							<span className="box-label">ייצור</span>
							<span className="box-description">יִיצור הוא תהליך של הפקת מוצר. תהליך זה הוא חלק חשוב בכלכלה. איכות הייצור תלויה במספר גורמים כמו איכות האנשים העוסקים בייצור</span>
						</div>
						<div className="our-work-box nekyon">
							<div className="icon">
								<i className="fa-solid fa-broom"></i>
							</div>
							<span className="box-label">ניקון</span>
							<span className="box-description">להיות עובד ניקיון כיום זה כבר הרבה מעבר להחזקת מגב ומעבר עם סמרטוט במשרד. כיום, נדרש עובד ניקיון לידע ובקיאות רבה בטכניקות ובטכנולוגיות ניקיון</span>
						</div>
						<div className="our-work-box malon">
							<div className="icon">
								<i className="fa-solid fa-hotel"></i>
							</div>
							<span className="box-label">בתי מלון</span>
							<span className="box-description">העובדים בענף המלונאות נהנים מתנאי העסקה איכותיים.</span>
						</div>
					</div>
				</div>
				<div className="fill-data" ref={(ref) => (this.formRef = ref)}>
					<p className="form-title">נציגנו רוצים לתת לכם את השירות הטוב ביותר! השאר את פרטיך והם יחזרו אליך תוך 24</p>
					<form onSubmit={this.handleSubmitBtn} onChange={this.onValueChange} className="fill-data-form">
						<input className="input-style" name="full_name" type="text" placeholder="שם מלא" required />
						<input className="input-style" name="email" type="email" placeholder="מייל" required />
						<input className="input-style" name="phone_number" type="number" placeholder="טלפון" required onWheel={(e) => e.target.blur()} />
						<select className="input-style" name="job">
							<option>בחר תחום עבודה</option>
							<option>סיעוד</option>
							<option>מפעלים</option>
							<option>ייצור</option>
							<option>ניקון</option>
							<option>בתי מלון</option>
						</select>
						<p className="error-message">{this.state.error_message}</p>
						<button name="submitBtn" className="submit-button">{this.state.button_value}</button>
					</form>
				</div>
			</div>
		);
	}
}

export default Home;
